<template>
  <b-tab title="Work Hours Information" lazy class="p-0">
    <b-card no-body class="border-0">
      <template #header>
        <strong>Work Hours Information</strong>
      </template>
      <b-row no-gutters cols-md="2" cols="1">
        <b-col class="p-2">
          <b-row cols="2" v-if="displayWorkHours">
            <b-col><strong>Total Work Hours:</strong></b-col>
            <b-col>{{ member.totalWorkHours }}</b-col>
          </b-row>
          <b-row cols="2" v-if="displayWorkHoursSince">
            <b-col><strong>Total Work Hours since Last Upgrade:</strong></b-col>
            <b-col>{{ member.workHoursSinceUpgrade  }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-tab>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class WorkHoursView extends Vue {
  get member() {
    const mem = this.$store.getters.currentMember;
    return !!mem && mem.isValid ? mem : {};
  }

  get displayWorkHours() {
    return this.$store.getters['timeCard/displayTotalWorkHours'];
  }

  get displayWorkHoursSince() {
    return this.$store.getters['timeCard/displayWorkHoursSinceUpgrade'];
  }
}
</script>
