<template>
  <div>
    <b-table
      :fields="fields"
      :items="tableData"
      :table-variant="variant"
      :per-page="pageSize"
      :current-page="selectedPage"
      :tbody-tr-class="rowClass"
      no-local-sorting
      sort-icon-left
      :sort-desc="sort.desc"
      :sort-by="sort.by"
      striped
      stacked="md"
      small
      head-variant="dark"
      @sort-changed="sortChanged">
      <template #cell(contractor)="row">
        {{ row.item.contractor }}
      </template>
      <template #cell(startDate)="row">
        {{ row.item.startDate | formatDate }}
      </template>
      <template #cell(endDate)="row">
        {{ row.item.endDate | formatDate }}
      </template>
      <template #cell(hoursWorked)="row">
        {{ row.item.hoursWorked }}
      </template>
      <template #cell(workType)="row">
        {{ row.item.workType }}
      </template>
      <template #cell(buttons)="row">
        <template v-if="canEdit(row.item)">
          <b-row>
            <b-col class="text-right">
              <b-icon icon="pencil-square" variant="primary" class="icon-button" :id="'edit-' + row.item.id" @click="edit(row.item)" />
              <b-tooltip :target="'edit-' + row.item.id" triggers="hover">
                <strong>Edit</strong>
                {{ row.item.contractor }}<br />
                ({{ row.item.startDate | formatDate }} - {{ row.item.endDate | formatDate }})
              </b-tooltip>
              <b-icon icon="trash" variant="danger" class="ml-2 icon-button" :id="'trash-' + row.item.id" @click="deleteEntry(row.item)" />
              <b-tooltip :target="'trash-' + row.item.id" triggers="hover">
                <strong>Delete</strong>
                {{ row.item.contractor }}<br />
                ({{ row.item.startDate | formatDate }} - {{ row.item.endDate | formatDate }})
              </b-tooltip>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-table>
    <time-card-modal
      ref="editEntryModal"
      :title="editModalTitle"
      :entry="editEntry"
      @request-save="saveRequested"
      @canceled="saveCanceled"
      @closed="saveDialogClosed" />
  </div>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'underscore';
import { TimeCardEntry } from '@/pages/time_card/model/time_card';
import TypeAhead from '@/components/TypeAhead';
import { trimToEmpty } from '@/util/text';
import { PREFERENCES } from '@/model/training_center';
import PickerOfDates from '@/components/PickerOfDates';
import TimeCardModal from '@/pages/time_card/views/TimeCardModal';
import { isBefore, isAfter, addDays, format } from 'date-fns';

@Component({
  components: {
    PickerOfDates,
    TypeAhead,
    TimeCardModal
  }
})
export default class TimeCardTable extends Vue {
  @Prop({ type: Array }) rows;
  @Prop({ type: Number, default: 0 }) perPage;
  @Prop({ type: Number, default: 0 }) currentPage;
  @Prop({ type: String, default: null }) variant;

  editableEntry = new TimeCardEntry({});

  feedback = {
    contractor: 'Contractor is Required',
    startDate: 'From Date is Required',
    endDate: null,
    hours: null,
    workType: 'Work Type is Required.'
  };

  sort = {
    by: 'startDate',
    desc: true
  };

  get pageSize() {
    return 0 < this.perPage ? this.perPage : this.rows.length;
  }

  get selectedPage() {
    return Math.max(this.currentPage);
  }

  get minEditDate() {
    return this.$store.getters['timeCard/minEditDate'];
  }

  get maxEditDate() {
    return this.$store.getters['timeCard/maxEditDate'];
  }

  get contractors() {
    return this.$store.getters.contractors;
  }

  get pinnedContractors() {
    return _.filter(this.contractors, (c) => c.name === 'Other');
  }

  eEntry = null;

  get editEntry() {
    return this.eEntry instanceof TimeCardEntry ? this.eEntry : TimeCardEntry.create({});
  }

  set editEntry(ee) {
    this.eEntry = ee;
  }

  get editModalTitle() {
    const entry = this.editEntry;
    const sd = entry.startDate;
    const ed = entry.endDate;

    return 'Edit Entry' + (_.isDate(sd) && _.isDate(ed) ? ` from ${format(sd, 'M/d/yyyy')} to ${format(ed, 'M/d/yyyy')}` : '');
  }

  get fields() {
    const hasWorkTypes = this.$store.getters['timeCard/enableWorkType'];

    const fields = [
      {
        key: 'contractor',
        label: 'Contractor',
        sortable: true
      },
      {
        key: 'startDate',
        label: 'From',
        sortable: true
      },
      {
        key: 'endDate',
        label: 'To',
        sortable: true
      },
      {
        key: 'hoursWorked',
        label: 'Hours',
        sortable: true
      }
    ];

    if (hasWorkTypes) {
      fields.push({
        key: 'workType',
        label: 'Work Type',
        sortable: true
      });
    }

    fields.push({
      key: 'buttons',
      label: '',
      sortable: false
    });

    return fields;
  }

  get tableData() {
    const rows = _.sortBy(this.rows, (r) => {
      switch (this.sort.by) {
        case 'startDate':
        case 'endDate':
        case 'hoursWorked':
          return r[this.sort.by];

        default:
          return trimToEmpty(r[this.sort.by]).toLowerCase();
      }
    });

    if (this.sort.desc) {
      rows.reverse();
    }

    return rows;
  }

  sortChanged({ sortBy, sortDesc }) {
    this.sort.by = sortBy;
    this.sort.desc = sortDesc;
  }

  rowClass(row) {
    switch (true) {
      case _.contains(['success', 'warning', 'danger'], row.highlight):
        return `table-${row.highlight}`;
      default:
        return null;
    }
  }

  getPreference(preference) {
    const [clientKey] = preference;
    return this.$store.getters['timeCard/' + clientKey];
  }

  canEdit(entry) {
    const daysCanModify = this.getPreference(PREFERENCES.MODIFIED_DAYS);
    return entry.editable && isAfter(addDays(entry.created, daysCanModify), new Date());
  }

  edit(entry) {
    entry.highlight = 'warning';
    this.editEntry = entry;
    this.$refs.editEntryModal.show();
  }

  async saveRequested() {
    try {
      this.$refs.editEntryModal.showOverlay();
      const savedEntry = await this.$store.dispatch('timeCard/saveEntry', this.editEntry.workspace);

      if (savedEntry) {
        this.editEntry.commit();
        this.$refs.editEntryModal.hide(true);

        this.$bvToast.toast('Time Entry has been saved!', { title: 'Success', solid: true, variant: 'success' });
      }
    } catch (err) {
      // TODO:
    } finally {
      this.$refs.editEntryModal.hideOverlay();
    }
  }

  saveDialogClosed() {
    this.editEntry.highlight = false;
  }

  saveCanceled() {
    this.editEntry.rollback();
  }

  async deleteEntry(entry) {
    entry.highlight = 'danger';

    const h = this.$createElement;
    const message = h('div', {}, [
      h('div', {}, ['Are you sure you want to delete the highlighted row?']),
      h('div', { class: ['text-center', 'mt-2'] }, [h('strong', {}, ['This cannot be undone!'])])
    ]);

    const areYouSure = await this.$bvModal.msgBoxConfirm(message, {
      size: 'sm',
      centered: true,
      buttonSize: 'sm',
      headerBgVariant: 'danger',
      headerBorderVariant: 'danger',
      headerTextVariant: 'white',
      footerBgVariant: 'dark',
      footerTextVariant: 'white',
      title: 'Confirm Delete',
      okTitle: 'Yes, Delete',
      okVariant: 'danger',
      cancelTitle: 'Cancel',
      cancelVariant: 'success'
    });

    entry.highlight = false;

    if (areYouSure) {
      this.$store.dispatch('timeCard/deleteEntry', entry);
    }
  }
}
</script>
<style scoped></style>
