<template>
  <b-tab title="Reports" lazy class="p-0">
    <b-card no-body class="border-0">
      <b-card-header>
        <strong>Reports</strong>
      </b-card-header>
      <b-list-group class="report-list" flush>
        <b-list-group-item>
          <b-form inline>
            <label for="report-selected-date" class="mr-3">Show Entries For: </label>
            <picker-of-dates id="report-selected-date" placeholder="All Time" type="month" format="MMMM yyyy" v-model="selectedDate">
            </picker-of-dates>
          </b-form>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="10">
              <h3>
                Work Hours
                <small class="text-muted">
                  The Work Hours Report provides a table of reported work hours by Work Hours Type. The table contains the contractor, notes, work
                  type and work hours reported for each date range. Total Work Hours by Type, Year(s) and Month(s) are also displayed.
                </small>
              </h3>
            </b-col>
            <b-col md="2" class="text-right">
              <b-button variant="info" size="sm" @click="downloadReport('all')"> <b-icon-download /> Download </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="10">
              <h3>
                Work Hours by Type
                <small class="text-muted">
                  The Work Hours by Type Report provides a table of reported work hours by Work Type. The table contains the contractor, notes and
                  work hours reported for each date range.
                </small>
              </h3>
            </b-col>
            <b-col md="2" class="text-right">
              <b-button variant="info" size="sm" @click="downloadReport('by_type')"> <b-icon-download /> Download </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-tab>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import _ from 'underscore';
import PickerOfDates from '@/components/PickerOfDates';
import downloader from '@/util/downloader';

@Component({
  components: {
    PickerOfDates
  }
})
export default class TimeCardReports extends Vue {
  selectedDate = null;

  get user() {
    return this.$store.getters['session/currentUser'] || {};
  }

  get ubcId() {
    return this.user.ubcId;
  }

  async downloadReport(report) {
    const ubcId = this.ubcId;

    if (!ubcId) {
      return;
    }

    try {
      const selectedDate = this.selectedDate;
      const year = _.isDate(selectedDate) ? selectedDate.getFullYear() : 0;
      const month = _.isDate(selectedDate) ? selectedDate.getMonth() + 1 : 0;
      const url = `/api/report/time_card/work_hours/${report}/${this.ubcId}/${month}/${year}`;
      await downloader.get(url, `work_hours${report === 'all' ? '' : '_by_type'}.pdf`);
    } catch (err) {
      this.$bvToast.toast(err.message, {
        title: 'Error',
        solid: true,
        variant: 'danger'
      });
    }
  }
}
</script>
