<template>
  <b-tab title="Submit Work Hours" lazy class="p-0">
    <b-card no-body class="border-0">
      <b-card-header>
        <strong>Submit Work Hours</strong>
      </b-card-header>
      <b-card-header class="p-0">
        <b-row>
          <b-col>
            <b-card no-body header-bg-variant="warning" class="border-0 mt-3">
              <template #header>
                <div class="filter-heading clearfix" @click="filtersVisible = !filtersVisible">
                  <strong><b-icon-filter /> Filter Work Hours</strong>
                  <span class="float-right">
                    <b-icon-chevron-double-up v-if="filtersVisible" />
                    <b-icon-chevron-double-down v-else />
                  </span>
                </div>
              </template>
              <b-collapse v-model="filtersVisible">
                <b-row class="m-2">
                  <b-col>
                    <b-form inline>
                      <label class="m-2">Contractor:</label>
                      <b-input-group>
                        <type-ahead
                          placeholder="Filter by Contractor"
                          size="sm"
                          value-attribute="id"
                          text-attribute="name"
                          :state="null"
                          :items="contractors"
                          v-model="filters.contractor" />
                        <template #append>
                          <b-button size="sm" @click="filters.contractor = null">
                            <b-icon-x />
                          </b-button>
                        </template>
                      </b-input-group>
                      <label class="ml-2 mr-2">Year:</label>
                      <b-input-group>
                        <b-select size="sm" v-model="filters.year" :options="filterYears">
                          <template #first>
                            <option :value="null">Filter by Year</option>
                          </template>
                        </b-select>
                        <template #append>
                          <b-button size="sm" @click="filters.year = null">
                            <b-icon-x />
                          </b-button>
                        </template>
                      </b-input-group>
                      <label class="ml-2 mr-2">Work Type:</label>
                      <b-input-group>
                        <b-select size="sm" :options="filterWorkTypes" v-model="filters.workType">
                          <template #first>
                            <option :value="null">Filter by Work Type</option>
                          </template>
                        </b-select>
                        <template #append>
                          <b-button size="sm" @click="filters.workType = null">
                            <b-icon-x />
                          </b-button>
                        </template>
                      </b-input-group>
                    </b-form>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="ml-3">
            <b-pagination size="sm" v-model="page.current" :total-rows="numRows" :per-page="page.size" />
          </b-col>
          <b-col class="text-right mr-3">
            <b-button size="sm" variant="primary" @click="addHours">
              <b-icon-plus-circle />
              Add Work Hours
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <time-card-table :rows="tableData" :per-page="page.size" :current-page="page.current" />
    </b-card>
    <time-card-modal ref="addTimeModal" title="Record Work Hours" :entry="newEntry" @request-save="saveRequested" />
  </b-tab>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import TimeCardModal from '@/pages/time_card/views/TimeCardModal';
import _ from 'underscore';
import { TimeCardEntry } from '@/pages/time_card/model/time_card';
import TimeCardTable from '@/pages/time_card/views/TimeCardTable';
import TypeAhead from '@/components/TypeAhead';

@Component({
  components: {
    TimeCardModal,
    TimeCardTable,
    TypeAhead
  }
})
export default class TimeCardEntriesView extends Vue {
  page = {
    current: 1,
    size: 10
  };

  filtersVisible = false;

  filters = {
    year: null,
    contractor: null,
    workType: null
  };

  nEntry = null;

  get newEntry() {
    if (null === this.nEntry) {
      this.nEntry = TimeCardEntry.create({ ubcId: this.ubcId });
    }

    return this.nEntry;
  }

  set newEntry(ne) {
    this.nEntry = ne;
  }

  get filterYears() {
    return _.chain(this.entries)
      .map((row) => row.startDate.getFullYear())
      .unique()
      .sortBy(_.identity)
      .reverse()
      .value();
  }

  get numRows() {
    return this.tableData.length;
  }

  get numPages() {
    return Math.ceil(this.numRows / this.page.size);
  }

  get currentMember() {
    return this.$store.getters.currentMember || {};
  }

  get ubcId() {
    return this.currentMember.ubcId;
  }

  get filterWorkTypes() {
    return _.chain(this.entries)
      .filter((e) => !!e.workType)
      .map((e) => e.workType)
      .unique()
      .sortBy(_.identity)
      .value();
  }

  get entries() {
    return this.$store.getters['timeCard/entries'];
  }

  get contractors() {
    return this.$store.getters.contractors;
  }

  get filterContractors() {
    const ids = _.chain(this.entries)
      .map((e) => e.contractorId)
      .unique()
      .value();
    return _.chain(this.contractors)
      .filter((c) => _.contains(ids, c.id))
      .map((c) => c.name)
      .sortBy(_.identity)
      .value();
  }

  get tableData() {
    return _.chain(this.entries)
      .filter((e) => _.isNull(this.filters.year) || e.startDate.getFullYear() === this.filters.year)
      .filter((e) => _.isNull(this.filters.contractor) || e.contractorId === this.filters.contractor.id)
      .filter((e) => _.isNull(this.filters.workType) || e.workType === this.filters.workType)
      .reverse()
      .value();
  }

  addHours() {
    this.newEntry = TimeCardEntry.create({ ubcId: this.ubcId });
    this.$refs.addTimeModal.show();
  }

  async saveRequested() {
    try {
      this.$refs.addTimeModal.showOverlay();

      const savedEntry = await this.$store.dispatch('timeCard/addEntry', this.newEntry.workspace);

      if (savedEntry) {
        this.$refs.addTimeModal.hide(true);
      }
    } catch (err) {
      // TODO:
    } finally {
      this.$refs.addTimeModal.hideOverlay();
    }
  }
}
</script>
