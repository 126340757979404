<template>
  <b-card no-body class="p-0 m-0">
    <b-tabs small card v-if="showTabs">
      <time-card-entries-view v-if="isTimeCardEnabled && isApprentice" />
      <b-tab :title="tabTitle" v-else-if="!isTimeCardEnabled">
        <b-card class="border-0">
          <h3>The option to log work hours has been removed by your Training Center. Please contact the Training Center directly with questions.</h3>
        </b-card>
      </b-tab>
      <b-tab :title="tabTitle" v-else>
        <b-card class="border-0">
          <h3>This feature is only for active apprentices.</h3>
        </b-card>
      </b-tab>
      <time-card-reports-view />
      <work-hours-view v-if="showWorkHoursTab" />
    </b-tabs>
  </b-card>
</template>
<script>
import { Vue, Component, Watch } from 'vue-property-decorator';
import TimeCardEntriesView from '@/pages/time_card/views/TimeCardEntriesView';
import TimeCardReportsView from '@/pages/time_card/views/TimeCardReportsView';
import WorkHoursView from '@/pages/time_card/views/WorkHoursView';
import _ from 'underscore';

@Component({
  components: {
    TimeCardEntriesView,
    TimeCardReportsView,
    WorkHoursView
  },
  metaInfo: {
    title: 'Time Card'
  }
})
export default class TimeCard extends Vue {
  get tabTitle() {
    return 'Submit Work Hours';
  }

  get currentMember() {
    return this.$store.getters.currentMember || {};
  }

  get ubcId() {
    return this.currentMember.ubcId;
  }

  get isApprentice() {
    return this.$store.getters.isApprentice;
  }

  get isTimeCardEnabled() {
    return this.$store.getters['timeCard/enableTimeCard'];
  }

  get showTabs() {
    return this.$store.getters['timeCard/entriesLoaded'];
  }

  get showWorkHoursTab() {
    return this.$store.getters['timeCard/displayTotalWorkHours'] || this.$store.getters['timeCard/displayTotalWorkHours'];
  }

  loadAttempts = 0;

  async loadEntries() {
    if (this.loadAttempts++ > 8) {
      this.loadAttempts = 0;
      return;
    }

    const ubcId = (this.ubcId || '').trim();

    if (_(ubcId).isEmpty()) {
      return setTimeout(async () => {
        await this.loadEntries();
      }, 250);
    }

    const loaded = await this.$store.dispatch('timeCard/loadEntries', { ubcId });

    if (!loaded) {
      // TODO: If it fails to load, should we really try again 1/2 second later??
      setTimeout(() => this.loadEntries.bind(this), 500);
    }
  }

  async mounted() {
    await this.loadEntries();
  }
}
</script>
<style scoped></style>
