<template>
  <b-row>
    <b-col>
      <b-input-group>
        <b-input
          :id="inputId"
          ref="mainInput"
          :size="size"
          :placeholder="placeholder"
          :state="inputState"
          @focus="onFocus"
          @blur="onBlur"
          v-model="model"
          v-b-tooltip.hover
          :title="tooltip" />
        <template #append v-if="hasAppend">
          <slot name="append"></slot>
        </template>
      </b-input-group>
      <div v-if="showOptions" class="type-ahead-container">
        <b-list-group flush class="type-ahead-options-container">
          <b-list-group-item
            v-for="option in options"
            variant="light"
            class="type-ahead-option"
            :key="option[valueAttribute]"
            @click="selectItem(option)">
            {{ option[textAttribute] }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

@Component
export default class TypeAhead extends Vue {
  @Prop({ type: Object }) value;
  @Prop({ type: String, default: 'md' }) size;
  @Prop({ type: String, default: '' }) placeholder;
  @Prop({ type: String, default: 'id' }) valueAttribute;
  @Prop({ type: String, default: 'text' }) textAttribute;
  @Prop({ type: Number, default: 10 }) itemsToShow;
  @Prop({ type: Array }) items;
  @Prop({ type: Array, default: () => [] }) pinnedItems;
  @Prop({ type: Boolean }) state;
  @Prop({ type: String }) tooltip;

  inputValue = null;
  showOptions = false;
  inputId = uuidv4();

  get model() {
    return (this.value || {})[this.textAttribute];
  }

  set model(value) {
    this.inputValue = value;
  }

  get options() {
    return _.filter(this.items, (item) => {
      const input = (this.inputValue || '').trim().toUpperCase();
      return _.isEmpty(input) || (item[this.textAttribute] || '').toUpperCase().startsWith(input) || this.isPinned(item);
    });
  }

  get hasAppend() {
    return !!this.$slots.append;
  }

  get inputState() {
    return this.state;
  }

  isPinned(item) {
    return !!_.find(this.pinnedItems, (i) => item[this.valueAttribute] === i[this.valueAttribute]);
  }

  onFocus() {
    this.showOptions = true;
  }

  onBlur() {
    setTimeout(() => (this.showOptions = false), 200);
  }

  selectItem(item) {
    this.$emit('input', item);
    this.model = item[this.textAttribute];
    this.showOptions = false;
    this.$refs.mainInput.blur();
  }

  @Watch('value')
  valueWatch(v) {
    this.setTextualValue(v);
  }

  mounted() {
    this.setTextualValue(this.value);
  }

  setTextualValue(v) {
    this.model = !v ? null : v[this.textAttribute];
  }
}
</script>
<style scoped>
div.type-ahead-container {
  position: relative;
  z-index: 1000;
}

div.type-ahead-options-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: lightgray;
  border-radius: 5px;
  box-shadow: 0 0 1px black;
  padding: 0;
  overflow: auto;
  max-height: 100px;
}

div.list-group-item.type-ahead-option {
  cursor: pointer;
  margin: 0;
  padding: 2px 10px;
  font-size: 10px;
}
</style>
