<template>
  <b-modal
    ref="modal"
    :title="title"
    no-close-on-backdrop
    no-close-on-esc
    @close="hide(false, $event)"
    header-bg-variant="dark"
    header-text-variant="white"
    footer-bg-variant="dark"
    footer-border-variant="white">
    <b-overlay variant="secondary" :opacity="0.04" :show="overlayVisible">
      <b-form-group label="Contractor:" :state="validContractor" invalid-feedback="Contractor is Required">
        <type-ahead
          size="sm"
          placeholder="Type or Select a Contractor"
          value-attribute="id"
          text-attribute="name"
          :items="contractors"
          :pinned-items="pinnedContractors"
          :state="validContractor"
          v-model="contractor"
          :tooltip="tooltip">
          <template #append>
            <b-button size="sm" @click="contractor = null">
              <b-icon-x />
            </b-button>
          </template>
        </type-ahead>
      </b-form-group>
      <b-form-group
        label="Work Period:"
        :invalid-feedback="dateRangeFeedback"
        :state="validStartDate && validEndDate"
        description="Select the date range you worked.">
        <picker-of-dates
          size="sm"
          placeholder="Select Date Range"
          format="M/d/yyyy"
          range
          icon-variant="secondary"
          :clearable="false"
          :min-date="minDate"
          :max-date="maxDate"
          :state="validStartDate && validEndDate"
          v-model="dateRange" />
      </b-form-group>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Work Hours:" :state="validWorkHours" :invalid-feedback="workHoursFeedback">
            <b-input-group>
              <b-input size="sm" type="number" placeholder="Work Hours" :state="validWorkHours" v-model="workHours" :min="0" />
              <template #append>
                <b-button size="sm" @click="workHours = null">
                  <b-icon-x />
                </b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6" v-if="showWorkTypes">
          <b-form-group label="Work Type:" :state="validWorkType" invalid-feedback="Work Type is Required">
            <b-input-group>
              <b-select size="sm" :options="workTypes" v-model="workType" :state="validWorkType">
                <template #first>
                  <option :value="null">-</option>
                </template>
              </b-select>
              <template #append>
                <b-button size="sm" @click="workType = null">
                  <b-icon-x />
                </b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-overlay>
    <template #modal-footer>
      <b-button-group size="sm">
        <b-button variant="danger" @click="hide(false)" :disabled="overlayVisible">Cancel</b-button>
        <b-button variant="success" @click="requestSave" :disabled="overlayVisible">Save</b-button>
      </b-button-group>
    </template>
  </b-modal>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import TypeAhead from '@/components/TypeAhead';
import PickerOfDates from '@/components/PickerOfDates';
import { TimeCardEntry } from '@/pages/time_card/model/time_card';
import _ from 'underscore';
import { isAfter, isBefore, isEqual, parseISO, startOfDay, differenceInDays } from 'date-fns';

@Component({
  components: {
    TypeAhead,
    PickerOfDates
  }
})
export default class TimeCardModalB extends Vue {
  @Prop({ type: String }) title;
  @Prop({ type: TimeCardEntry }) entry;

  overlayVisible = false;

  get tooltip() {
    return `Please select "Other" if your Contractor is not listed.`;
  }

  get contractors() {
    return this.$store.getters.contractors;
  }

  get contractor() {
    return {
      id: this.entry.workspace.contractorId,
      name: this.entry.workspace.contractor
    };
  }

  get validContractor() {
    const cId = parseInt(this.contractor.id, 10);
    return this.$store.getters['timeCard/requireContractor'] ? !_.isNaN(cId) : null;
  }

  set contractor(c) {
    this.entry.workspace.contractorId = (c || {}).id;
    this.entry.workspace.contractor = (c || {}).name;
  }

  get pinnedContractors() {
    return _.filter(this.contractors, (c) => c.name === 'Other');
  }

  get minDate() {
    return this.$store.getters['timeCard/minEditDate'];
  }

  get maxDate() {
    return this.$store.getters['timeCard/maxEditDate'];
  }

  get startDate() {
    return this.entry.workspace.startDate;
  }

  set startDate(sd) {
    this.entry.workspace.startDate = sd;
  }

  get endDate() {
    return this.entry.workspace.endDate;
  }

  set endDate(ed) {
    this.entry.workspace.endDate = ed;
  }

  get rangeDays() {
    return Math.abs(differenceInDays(this.startDate, this.endDate));
  }

  get dateRange() {
    return [this.startDate, this.endDate];
  }

  set dateRange([s, e]) {
    this.startDate = s;
    this.endDate = e;
  }

  get validStartDate() {
    const isEqualOrBefore = isEqual(this.startDate, this.endDate) || isBefore(this.startDate, this.endDate);
    return isEqualOrBefore && this.validDateRange;
  }

  get validEndDate() {
    const isAfterOrEqual = isEqual(this.startDate, this.endDate) || isAfter(this.endDate, this.startDate);
    return isAfterOrEqual && this.validDateRange;
  }

  get validDateRange() {
    return this.$store.getters['timeCard/mayNotExceed'] > this.rangeDays;
  }

  get dateRangeFeedback() {
    const limitDays = this.$store.getters['timeCard/mayNotExceed'];

    switch (true) {
      case limitDays <= this.rangeDays:
        return `The range between From and To dates may not exceed ${limitDays} day(s).`;

      default:
        return 'Work Period is Required.';
    }
  }

  get workHours() {
    return this.entry.workspace.hoursWorked;
  }

  set workHours(wh) {
    this.entry.workspace.hoursWorked = parseFloat(wh) || null;
  }

  get validWorkHours() {
    const hours = parseFloat(this.workHours);
    return !_.isNaN(hours) && hours !== Infinity && 0 < hours;
  }

  get workHoursFeedback() {
    const hours = parseFloat(this.workHours);

    switch (true) {
      case 0 >= hours:
        return 'Work Hours must be greater than 0.';
      default:
        return 'Work Hours is Required.';
    }
  }

  get workTypes() {
    return (this.$store.getters.trainingCenter || {}).workTypes || [];
  }

  get showWorkTypes() {
    return this.$store.getters['timeCard/enableWorkType'];
  }

  get workType() {
    return this.entry.workspace.workType || null;
  }

  set workType(wt) {
    this.entry.workspace.workType = wt;
  }

  get validWorkType() {
    if (!this.showWorkTypes || !this.$store.getters['timeCard/requireWorkType']) {
      return null;
    }

    return !_.isEmpty(this.workType);
  }

  show() {
    this.$refs.modal.show();
  }

  hide(skipPrompt, $event) {
    if (_.isFunction(($event || {}).preventDefault)) {
      $event.preventDefault();
    }

    if (true === skipPrompt || !this.entry.isDirty) {
      return this.$nextTick(() => {
        this.$refs.modal.hide();
        this.$emit('closed');
      });
    }

    this.$bvModal
      .msgBoxConfirm('There are unsaved changes. Are you sure you want to close this dialog?', {
        centered: true,
        headerBgVariant: 'danger',
        headerTextVariant: 'white',
        footerBgVariant: 'dark',
        size: 'sm',
        buttonSize: 'sm',
        title: 'Unsaved Changes!',
        okTitle: 'Yes',
        okVariant: 'danger',
        cancelTitle: 'No',
        cancelVariant: 'success'
      })
      .then((yesClose) => {
        if (yesClose) {
          this.$emit('canceled');
          this.$emit('closed');
          this.$refs.modal.hide();
        }
      });
  }

  showOverlay() {
    this.overlayVisible = true;
  }

  hideOverlay() {
    this.overlayVisible = false;
  }

  requestSave() {
    if ([this.validContractor, this.validStartDate, this.validEndDate, this.validWorkHours, this.validWorkType].some((v) => v === false)) {
      return this.$bvToast.toast('Please correct the highlighted errors before saving', {
        title: 'Error Saving Work Hours',
        variant: 'danger',
        solid: true
      });
    }

    this.$emit('request-save');
  }
}
</script>
